<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form @submit.prevent="updateCompanyInfo" ref="companyInfoForm" class="custom-form">
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-card dark id="company_info" color="primary" :elevation="1">
                        <v-card-text class="pa-0 text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="position-relative">
                                        <v-dialog width="400">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large v-bind="attrs" v-on="on">
                                                    <v-avatar color="primary darken-1" size="120">
                                                        <v-img v-if="logoPreview" :src="logoPreview"></v-img>
                                                        <v-icon v-else dark>mdi-account-circle</v-icon>
                                                    </v-avatar>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title class="grey lighten-2 text-center">
                                                    <v-img v-if="logoPreview" :src="logoPreview"></v-img>
                                                    <v-img v-else :src="$store.state.host + 'no_image.svg'"></v-img>
                                                </v-card-title>
                                            </v-card>
                                        </v-dialog>
                                        <v-file-input class="company-logo-input" hide-input truncate-length="15"
                                            accept=".png, .jpg" v-model="selectedLogo"></v-file-input>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <h1 class="subtitle-2">{{ company.name ? company.name : "Company Name" }}</h1>
                                    <p class="caption mb-0">
                                        {{ company.phone ? company.phone : "Company Phone" }}
                                    </p>
                                    <p class="caption mb-0">
                                        {{ company.email ? company.email : "Company Email" }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Personal Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-2">
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Name</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details v-model.trim="company.name"
                                        :rules="[(v) => !!v]" :error-messages="validation_errors.name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Phone</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details v-model.trim="company.phone"
                                        :rules="[(v) => !!v]" :error-messages="validation_errors.phone"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Email</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details v-model.trim="company.email"
                                        :rules="[(v) => !!v]" :error-messages="validation_errors.email"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Address</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea outlined hide-details :rows="2" v-model.trim="company.address"
                                        :rules="[(v) => !!v]" :error-messages="validation_errors.address"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Settings</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="py-2">
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Late Allow</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details v-model.trim="company.late_deduction_day"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.late_deduction_day"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Salary P.D</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details
                                        v-model.trim="company.salary_devided_by_for_per_day" :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.salary_devided_by_for_per_day"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 pr-1 caption">Stamp Ded.</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field dense outlined hide-details v-model.trim="company.stamp_deduction"
                                        :rules="[(v) => !!v]"
                                        :error-messages="validation_errors.stamp_deduction"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-row>
                    <v-col cols="12" md="12" class="text-right">
                        <v-btn height="30px" dark color="light-blue darken-2 mr-1" :elevation="1" type="submit"
                            :loading="loading">Update</v-btn>
                    </v-col>
                </v-row>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    name: "CompanyProfile",
    mixins: [utility],
    data() {
        return {
            company: {
                logo: null,
                name: '',
                phone: '',
                email: '',
                address: '',
                late_deduction_day: '',
                salary_devided_by_for_per_day: '',
                stamp_deduction: '',
            },
            logoPreview: null,
            selectedLogo: null,
            loading: false,
            validation_errors: {},
        }
    },

    watch: {
        selectedLogo(image) {
            this.logoPreview = null;
            if (image != null) {
                this.logoPreview = URL.createObjectURL(this.selectedLogo);
            }
        },
    },
    created() {
        this.getCompany();
    },
    methods: {
        async getCompany() {
            this.company = await this.$store.dispatch('company/getCompany', true);
            this.logoPreview = this.$store.state.host + this.company.logo;
        },
        async updateCompanyInfo() {
            if (!this.$refs.companyInfoForm.validate()) {
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let formData = new FormData();

            formData.append("company", JSON.stringify(this.company));

            if (this.selectedLogo)
                formData.append("logo", this.selectedLogo);

            let resObj = await this.$store.dispatch("company/updateCompany", {
                data: formData
            });

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
        }
    },
}
</script>

<style scoped>
.company-logo-input {
    position: absolute;
    bottom: -15px;
    left: 45%;
}

.position-relative {
    position: relative;
}

.v-sheet.v-card:not(#company_info) {
    background: #6ef6f63d !important;
}

#company_info,
#company_info .caption,
#company_info .subtitle-2 {
    color: #fff !important;
}

.v-data-table thead {
    background-color: #5df0f096 !important;
}

>>>.v-avatar {
    border-radius: 6px;
}

>>>.v-btn--icon.v-size--x-large {
    height: unset;
    width: unset;
}

>>>.v-dialog>.v-card>.v-card__title {
    padding: unset;
}

.theme--light.v-toolbar.v-sheet {
    background-color: #5df0f096 !important;
}

.v-card__text .caption {
    color: #000;
}


.v-sheet.v-card:not(#company_info) {
    background: #6ef6f63d !important;
}
</style>